import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler } from "@angular/common/http";
import { JwtService } from '../../jwt.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(private _jwt: JwtService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const token = this._jwt.getToken();

    // Set the headers for every request
    const finalReq = req.clone({
      withCredentials: true,
      headers: token
        ? req.headers.set('Content-Type', 'application/json').set('Authorization', `Bearer ${token}`).set('Accept', 'application/json')
        : req.headers.set('Content-Type', 'application/json').set('Accept', 'application/json')
    });

    return next.handle(finalReq);
  }
}
