<main>
  <router-outlet></router-outlet>
</main>
<ng-template #childModal let-modal>
  <div class="modal-header">
    <h4 id="dialog-child-name" class="modal-title">Your session is about to expire! <br /> Do you want to stay signed in?</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    {{idleState}}
    <div class="row mrgbtm">
      <div class="col-md-6">
        <br />
        <button type="button" (click)="logout()" class="btn btn-danger">Logout</button>
      </div>
      <div class="col-md-6">
        <br />
        <button type="button" (click)="stay()" class="btn btn-primary">Stay signed in</button>
      </div>
    </div>
  </div>
</ng-template>
