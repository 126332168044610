import { Injectable, ErrorHandler, Injector } from '@angular/core';

@Injectable({
providedIn: 'root'
})
export class ErrorMiddlewareService implements ErrorHandler{
  constructor(private _injector: Injector) { }

  /**
   * @summary Middleware unexpected error handler, can be used to do logging, notify server, handle gracefully.
   * @param error the error that will be passed to this middleware method
   *
   * Use the _injector to inject additional services for use here if needed (e.g Router)
   */
  handleError(error: any) {
    console.log(error)
  }
}
